<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Department</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Department</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="card">
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <div class="card-header">
          <h3 class="card-title">Detail</h3>
          <div class="card-tools">
            <router-link class="btn btn-info" :to="{name: 'Departments'}">
              <i class="fas fa-list"></i> List
            </router-link>
          </div>
        </div>
        <div class="card-body table-responsive p-0">
          <table class="table table-striped">
            <tbody>
            <tr>
              <td><b>Organization</b></td>
              <td>{{ !_.isEmpty(department.organization) ? department.organization.org_name : '' }}</td>
            </tr>
            <tr>
              <td><b>Head of Department</b></td>
              <td>{{ !_.isEmpty(department.admin_user) ? department.admin_user.name : '' }}</td>
            </tr>
            <tr>
              <td><b>Name</b></td>
              <td>{{ department.name }}</td>
            </tr>
            <tr>
              <td><b>Description</b></td>
              <td>{{ !_.isEmpty(department.description) ? department.description : '' }}</td>
            </tr>
            <tr>
              <td><b>Starting Date</b></td>
              <td>{{ !_.isEmpty(department.starting_date) ? department.starting_date : '' }}</td>
            </tr>
            <tr>
              <td><b>Created At</b></td>
              <td>{{ !_.isEmpty(department.created_at) ? department.created_at : '' }}</td>
            </tr>
            <tr>
              <td><b>Updated At</b></td>
              <td>{{ !_.isEmpty(department.updated_at) ? department.updated_at : '' }}</td>
            </tr>
            </tbody>
          </table>

        </div>
        <div class="card-footer">

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MasterLayout from '@/components/layouts/MasterLayoutComponent.vue';
import {DepartmentsAPI} from "@/services/api";

export default {
  name: 'DepartmentShow',
  data: () => ({
    department: {},
    is_loading: false
  }),
  mounted: function () {
    this.departmentShow(this.$route.params.id);
  },
  methods: {
    departmentShow(id) {
      DepartmentsAPI.show(id).then(department => {
        this.department = department;
      });
    }
  },
  created() {
    this.$emit('update:layout', MasterLayout);
  },
}
</script>

